<template>
    <modal @close="$emit('close')" :autoshow="true" ref="modal" :is-closeable="isCloseable">
        <template #title>POS Update: {{ updateObj.action_pretty }}</template>

        <slot>
            <h4>Status: {{ updateObj.status_pretty }}</h4>
            <div>
                <b>Data Sent</b>
                <pre class="json-data">
{{ updateObj.employee_data_redacted }}
                </pre>
            </div>

            <div>
                <b>Response</b>
                <pre class="json-data">
{{ updateObj.webhook_response }}
                </pre>
            </div>
        </slot>

        <template #footer>
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Close</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import api from '@/api'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [ModalMixin, ],
    props: ['client', 'employee', 'updateObj'],
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>

<style scoped>
.json-data {
    white-space: pre-wrap;
}
</style>
